<template>
  <div class="KCmodule">
    <Header title="案卷管理" index="首页" titleOne="工程专利" beforeTitle="案卷管理" />
    <div class="content">
      <div class="topUsers">
        <div class="flex-l">
          <el-select
            clearable
            v-model="patprojectParams.patent_type"
            placeholder="请选择专利类型"
            @change="changePatentType"
          >
            <el-option label="发明专利" value="1">发明专利</el-option>
            <el-option label="实用新型" value="2">实用新型</el-option>
            <el-option label="外观设计" value="3">外观设计</el-option>
            <el-option label="植物新品种" value="4">植物新品种</el-option>
            <el-option label="国家新药" value="5">国家新药</el-option>
            <el-option label="集成电路布图设计专有权" value="6">集成电路布图设计专有权</el-option>
            <el-option label="国家级农作物品种" value="7">国家级农作物品种</el-option>
            <el-option label="国家一级中药保护品种" value="8">国家一级中药保护品种</el-option>
            <el-option label="其中：国防专利" value="9">其中：国防专利</el-option>
          </el-select>
          <el-select
            clearable
            v-model="patprojectParams.status"
            placeholder="请选择专利状态"
            @change="changeStatus"
          >
            <el-option label="新申请" value="1"></el-option>
            <el-option label="初审合格" value="2"></el-option>
            <el-option label="发明公布" value="3"></el-option>
            <el-option label="发明实审" value="4"></el-option>
            <el-option label="授权" value="5"></el-option>
            <el-option label="驳回" value="6"></el-option>
            <el-option label="撤回" value="7"></el-option>
            <el-option label="专利权维持" value="8"></el-option>
            <el-option label="专利权失效" value="9"></el-option>
          </el-select>
          <el-date-picker
            v-model="patprojectParams.app_date"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="申请开始日期"
            end-placeholder="申请结束日期"
            @change="chooseTimeRange1"
          ></el-date-picker>
          <el-select
            clearable
            v-model="patprojectParams.tag"
            placeholder="请选择标签类型"
            @change="changeTag"
            :multiple="true"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item,index) in options"
              :key="index"
            ></el-option>
          </el-select>
          <el-input
            clearable
            v-model="patprojectParams.patent_name"
            placeholder="请输入专利名称"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-input
            clearable
            v-model="patprojectParams.app_code"
            placeholder="请输入专利号/申请号"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-input
            clearable
            v-model="patprojectParams.internal_code"
            placeholder="请输入内部编号"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-input
            clearable
            v-model="patprojectParams.applicant"
            placeholder="多申请人"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-input
            clearable
            v-model="patprojectParams.inventor"
            placeholder="发明人"
            @keyup.enter.native="handleSearch()"
          ></el-input>

          <el-input
            clearable
            v-model="patprojectParams.company_name"
            placeholder="请输入企业名称"
            @keyup.enter.native="handleSearch()"
          ></el-input>
        </div>
        <div class="btn_head">
          <!-- <el-button class="import" @click="tagEdit()">标签编辑</el-button> -->
          <el-button @click="manageImport()" class="import">案卷导入</el-button>
          <el-button @click="exportAll()" class="import">全部导出</el-button>
          <el-button class="import" @click="patentAdd()">新增</el-button>
          <el-button class="search" @click="handleSearch()">查询</el-button>
        </div>
      </div>
      <!-- <keep-alive> -->
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="patproject.data"
        style="width: 100%"
      >
        <!-- v-if="checkList.includes('企业名称')" -->
        <el-table-column
          v-if="headerStatus.company_name_status"
          prop="company_name"
          label="企业名称"
          :show-overflow-tooltip="true"
          width="100"
        >
          <template slot-scope="scope">
            {{
            scope.row.company_name ? scope.row.company_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.internal_code_status"
          prop="internal_code"
          label="内部编号"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.internal_code ? scope.row.internal_code : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.types_status"
          prop="patent_type"
          label="类型"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.patent_type ? scope.row.patent_type : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.app_code_status"
          prop="app_code"
          label="专利号/申请号"
          :show-overflow-tooltip="true"
          width="150"
        >
          <template slot-scope="scope">
            {{
            scope.row.app_code ? scope.row.app_code : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.app_date"
          prop="app_date"
          label="申请日期"
          :show-overflow-tooltip="true"
          width="100"
        >
          <template slot-scope="scope">
            {{
            scope.row.app_date ? scope.row.app_date : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.patent_name_status"
          prop="patent_name"
          label="专利名称"
          :show-overflow-tooltip="true"
          width="200"
        >
          <template slot-scope="scope">
            <img
              style="width: 18px;cursor: pointer;position: relative;top: 4px;"
              src="../../assets/images/bu_red.png"
              alt
            />
            {{
            scope.row.patent_name ? scope.row.patent_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column v-if="headerStatus.tags_status" prop="tag" label="标签" width="150">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="Bottom Center 提示文字" placement="bottom">
              <template slot="content">
                <div class="tooltip-content">
                  <span style="color: #A86201;" v-if="scope.row.tag.includes('系统重复提交')">
                    系统重复提交
                    <br />
                  </span>
                  <span style="color: #8408FF;" v-if="scope.row.tag.includes('专利转让/变更')">
                    专利转让/变更
                    <br />
                  </span>
                  <span style="color: #1CC2FF;" v-if="scope.row.tag.includes('非正常申请重写')">
                    非正常申请重写
                    <br />
                  </span>
                  <span style="color: #03B262;" v-if="scope.row.tag.includes('非正常申请撤回')">
                    非正常申请撤回
                    <br />
                  </span>
                  <span style="color: #FFA01D;" v-if="scope.row.tag.includes('退费')">
                    退费
                    <br />
                  </span>
                  <span style="color: #FF2255;" v-if="scope.row.tag.includes('驳回/撤回重写')">
                    驳回/撤回重写
                    <br />
                  </span>
                  <span style="color: #ADB1B4;" v-if="scope.row.tag.includes('假专利')">
                    假专利
                    <br />
                  </span>
                  <span style="color: #F74691;" v-if="scope.row.tag.includes('代提交')">
                    代提交
                    <br />
                  </span>
                </div>
              </template>
              <span v-if="!scope.row.tag">-</span>
              <span style="color: #A86201;" v-if="scope.row.tag.includes('系统重复提交')">系统重复提交</span>
              <span style="color: #8408FF;" v-else-if="scope.row.tag.includes('专利转让/变更')">专利转让/变更</span>
              <span style="color: #1CC2FF;" v-else-if="scope.row.tag.includes('非正常申请重写')">非正常申请重写</span>
              <span style="color: #03B262;" v-else-if="scope.row.tag.includes('非正常申请撤回')">非正常申请撤回</span>
              <span style="color: #FFA01D;" v-else-if="scope.row.tag.includes('退费')">退费</span>
              <span style="color: #FF2255;" v-else-if="scope.row.tag.includes('驳回/撤回重写')">驳回/撤回重写</span>
              <span style="color: #ADB1B4;" v-else-if="scope.row.tag.includes('假专利')">假专利</span>
              <span style="color: #F74691;" v-else-if="scope.row.tag.includes('代提交')">代提交</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.reduction_ratio"
          prop="reduction_ratio"
          label="减缓比例"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.reduction_ratio ? scope.row.reduction_ratio + '%': "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.inventor"
          prop="inventor"
          label="多申请人/发明人"
          :show-overflow-tooltip="true"
          width="150"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.inventor != null"
            >{{ scope.row.inventor ? scope.row.inventor : '-' }}</span>
            <span v-if="scope.row.inventor && scope.row.applicant">{{ ' / ' }}</span>
            <span
              v-if="scope.row.applicant != null"
            >{{ scope.row.applicant ? scope.row.applicant : '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.engineer_status"
          prop="engineer"
          label="工程师"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.engineer ? scope.row.engineer : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.process_status"
          prop="process"
          label="流程人"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.process ? scope.row.process : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.custom_manager_status"
          prop="custom_manager"
          label="客户经理"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.custom_manager ? scope.row.custom_manager : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.status"
          prop="status"
          label="专利状态"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.status ? scope.row.status : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.area_status"
          prop="area"
          label="区域"
          :show-overflow-tooltip="true"
          width="200"
        >
          <template slot-scope="scope">
            {{
            scope.row.area ? scope.row.area : "-"
            }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="headerStatus.business_type_status"
          prop="business_type"
          label="业务类型"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.business_type ? scope.row.business_type : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.certificate_date_status"
          prop="certificate_date"
          label="下证情况"
          :show-overflow-tooltip="true"
          width="100"
        >
          <template slot-scope="scope">
            {{
            scope.row.certificate_date ? scope.row.certificate_date : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.certificate_situation_status"
          prop="certificate_situation"
          label="证书分发情况"
          :show-overflow-tooltip="true"
          width="130"
        >
          <template slot-scope="scope">
            {{
            scope.row.certificate_situation ? scope.row.certificate_situation : "-"
            }}
          </template>
        </el-table-column>

        <el-table-column prop="operation" label="操作" fixed="right" width="150px">
          <template slot="header">
            <div class="table_header">
              <span>操作</span>
              <!-- <el-button type="primary" plain size="small">编辑表头</el-button> -->
              <img @click="headerEdit" src="../../assets/images/patent_table_header.png" alt />
            </div>
          </template>
          <template slot-scope="scope">
            <el-button class="edit" size="mini" @click="patentEdit(scope.row.id)">编辑</el-button>
            <el-button class="del" size="mini" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="表头编辑" :visible.sync="dialogTableVisible" width="300px">
        <el-checkbox
          label="企业名称"
          v-model="headerStatus.company_name_status"
          :checked="headerStatus.company_name_status"
        ></el-checkbox>
        <el-checkbox
          label="内部编号"
          v-model="headerStatus.internal_code_status"
          :checked="headerStatus.internal_code_status"
        ></el-checkbox>
        <el-checkbox
          label="类型"
          v-model="headerStatus.types_status"
          :checked="headerStatus.types_status"
        ></el-checkbox>
        <el-checkbox
          label="专利号/申请号"
          v-model="headerStatus.app_code_status"
          :checked="headerStatus.app_code_status"
        ></el-checkbox>
        <el-checkbox label="申请日期" v-model="headerStatus.app_date" :checked="headerStatus.app_date"></el-checkbox>
        <el-checkbox
          label="专利名称"
          v-model="headerStatus.patent_name_status"
          :checked="headerStatus.patent_name_status"
        ></el-checkbox>
        <el-checkbox
          label="标签"
          v-model="headerStatus.tags_status"
          :checked="headerStatus.tags_status"
        ></el-checkbox>
        <el-checkbox
          label="减缓比例"
          v-model="headerStatus.reduction_ratio"
          :checked="headerStatus.reduction_ratio"
        ></el-checkbox>
        <el-checkbox
          label="多申请人/发明人"
          v-model="headerStatus.inventor"
          :checked="headerStatus.inventor"
        ></el-checkbox>
        <el-checkbox
          label="工程师"
          v-model="headerStatus.engineer_status"
          :checked="headerStatus.engineer_status"
        ></el-checkbox>
        <el-checkbox
          label="流程人"
          v-model="headerStatus.process_status"
          :checked="headerStatus.process_status"
        ></el-checkbox>
        <el-checkbox
          label="业务经理"
          v-model="headerStatus.custom_manager_status"
          :checked="headerStatus.custom_manager_status"
        ></el-checkbox>
        <el-checkbox label="专利状态" v-model="headerStatus.status" :checked="headerStatus.status"></el-checkbox>
        <el-checkbox
          label="区域"
          v-model="headerStatus.area_status"
          :checked="headerStatus.area_status"
        ></el-checkbox>
        <el-checkbox
          label="业务类型"
          v-model="headerStatus.business_type_status"
          :checked="headerStatus.business_type_status"
        ></el-checkbox>
        <el-checkbox
          label="下证情况"
          v-model="headerStatus.certificate_date_status"
          :checked="headerStatus.certificate_date_status"
        ></el-checkbox>
        <el-checkbox
          label="证书分发情况"
          v-model="headerStatus.certificate_situation_status"
          :checked="headerStatus.certificate_situation_status"
        ></el-checkbox>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogTableVisible = false">取 消</el-button>
          <el-button type="primary" @click="headerSure()">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="收货地址" :visible.sync="dialogTags">
        <el-select
          style="width: 100%;"
          value-key="tag"
          multiple
          v-model="taglist"
          placeholder="请选择标签"
        >
          <el-option
            v-for="(item,index) in options"
            :label="item.name"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
        <el-table :data="tagsData">
          <el-table-column property="date" label="内部编号"></el-table-column>
          <el-table-column property="name" label="申请号"></el-table-column>
          <el-table-column property="address" label="专利名称"></el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="headerSureTags()">确 定</el-button>
        </div>
      </el-dialog>
      <!-- </keep-alive> -->
      <div class="block" v-if="patproject.total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="patprojectParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="patprojectParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="patproject.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
  
  <script>
// import { mapGetters, mapState } from "vuex";
export default {
  data () {
    return {
      headerStatus: {
        internal_code_status: true,//内部编号
        types_status: true,//类型
        app_code_status: true,// 专利号/申请号
        app_date: true,//申请日期
        patent_name_status: true,//专利名称
        tags_status: true,//标签
        reduction_ratio: true,//减缓比例
        inventor: true,// 多申请人/发明人
        engineer_status: true,//工程师
        process_status: true,//流程人
        custom_manager_status: true,//业务经理
        status: true,//专利状态  
        company_name_status: true,//企业名称
        area_status: true,//区域
        business_type_status: true,//业务类型
        certificate_date_status: true,//下证情况
        certificate_situation_status: true,//证书分发情况
      },
      checkList: [],
      taglist: [],//标签编辑 标签选择框 model
      options: [
        {
          name: '系统重复提交',
          id: '1'
        },

        {
          name: '专利转让/变更',
          id: '2'
        },

        {
          name: '非正常申请重写',
          id: '3'
        },
        {
          name: '非正常申请撤回',
          id: '4'
        },
        {
          name: '退费',
          id: '5'
        },
        {
          name: '驳回/撤回重写',
          id: '6'
        },
        {
          name: '假专利',
          id: '7'
        },
        {
          name: '代提交',
          id: '8'
        }
      ],
      tagsData: [],//标签编辑表格
      dialogTags: false,//标签弹框默认false
      dialogTableVisible: false,
      opt: { id: '0', name: '全部' },
      pay_status_list: [
        {
          status: 0,
          value: "-",
          color: "",
        },
        {
          status: 1,
          value: "待缴",
          color: "#FFBE2C",
        },
        {
          status: 2,
          value: "滞纳",
          color: "#FF5865",
        },
      ],
      loading: true,
      province: [],
      city: [],
      town: [],
      patprojectTotal: 0,
      patprojectParams: {
        page: 1,
        limit: 10,
        keywords: "",
        patent_name: "",
        patent_type: "",
        status: "",
        company_name: "",
        order: "id desc",
        start_time: "", //申请开始日期
        end_time: "", //申请结束日期
        tag: ''
      },
      privilige_list: {},
      patproject: [],
    };
  },
  activated () {
    // this.handleSearch();
    this.patprojectParams
    this.getList()
  },
  created () {
    this.getList()
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
    let status = localStorage.getItem('patent_header_status')
    if (JSON.parse(status)) {
      this.headerStatus = JSON.parse(status)
    }
  },
  methods: {
    manageImport () {
      this.$router.push('/patent/manage_import')
    },
    tagEdit () {
      this.dialogTags = true
    },
    patentEdit (id) {
      this.$router.push({ path: '/patent/patent_edit', query: { id: id, type: 'patent_info' } })
    },
    patentAdd () {
      this.$router.push({ path: '/patent/patent_edit', query: { type: 'patent_info' } })
    },
    getList () {
      this.axios.get('/api/project_patent/list', { params: { ...this.patprojectParams } }).then((res) => {
        this.patproject = res.data
        setTimeout(() => {
          this.loading = false
        }, 100)
      })
    },
    headerSureTags () {

    },
    headerSure () {
      localStorage.setItem('patent_header_status', JSON.stringify(this.headerStatus))
      this.dialogTableVisible = false
      this.$message.success('保存成功')
    },
    headerEdit (column) {
      this.dialogTableVisible = true
    },
    exportAll () {
      // this.$router.push('')
      this.$router.push({
        path: "/data/data_export/go_export",
        query: { item: "project_patent" },
      });
    },
    chooseTimeRange1 (t) {
      console.log(t);
      this.patprojectParams.start_time = t[0]
      this.patprojectParams.end_time = t[1]
      this.loading = true
      this.axios.get('/api/project_patent/list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        this.patproject = res.data
        this.loading = false
      })
    },
    handleSizeChange (val) {
      this.patprojectParams.limit = val;
      this.loading = true
      // this.$store.dispatch("getpatproList", this.patprojectParams);
      this.axios.get('/api/project_patent/list', { params: { ...this.patprojectParams } }).then((res) => {
        this.patproject = res.data
        setTimeout(() => {
          this.loading = false
        }, 100)
      })
    },
    handleCurrentChange (val) {
      this.patprojectParams.page = val;
      this.loading = true
      // this.$store.dispatch("getpatproList", { ...this.patprojectParams, keywords: '' });
      this.axios.get('/api/project_patent/list', { params: { ...this.patprojectParams, keywords: '' } }).then((res) => {
        this.patproject = res.data
        setTimeout(() => {
          this.loading = false
        }, 100)
      })
    },
    changePatentType () {
      this.loading = true
      this.axios.get('/api/project_patent/list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        this.patproject = res.data
        this.loading = false
      })
    },
    changeTag (x) {
      this.loading = true
      this.axios.get('/api/project_patent/list', { params: { ...this.patprojectParams, page: '', tag: x.join() } }).then((res) => {
        this.patproject = res.data
        this.loading = false
      })
    },
    changeStatus () {
      this.loading = true
      this.axios.get('/api/project_patent/list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        this.patproject = res.data
        this.loading = false
      })
    },
    handleSearch () {
      // this.$store.dispatch("getpatproList", { ...this.patprojectParams, page: '', limit: '' });
      this.loading = true
      this.axios.get('/api/project_patent/list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        this.patproject = res.data
        this.loading = false
      })
    },
    handleDelete (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/project_patent/delete", {
            params: {
              id: id,
            },
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getList()
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
  </script>
  
  <style scoped="scoped">
.flex-l {
  width: 80%;
}
.btn_head {
  width: 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  align-items: center;
}
/* div/deep/.el-button {
  margin-bottom: 20px;
} */
.topUsers {
  display: flex;
  align-items: center;
}
.oneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.KCmodule .import {
  background: #edeef4;
  color: #909399;
  border: none;
  height: 40px;
}

::v-deep .el-tabs__content {
  top: 20px !important;
}

::v-deep .topUsers .el-input {
  width: 224px !important;
  margin: 10px 0;
  margin-right: 20px;
}

.el-date-editor--daterange.el-input__inner {
  width: 270px;
  margin-right: 20px;
}
.el-checkbox {
  display: block;
  margin-top: 10px;
}
div/deep/.el-dialog__title {
  font-weight: bold;
}
.table_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table_header img {
  width: 20px;
  cursor: pointer;
  margin-right: 40px;
}
.dialog-footer {
  text-align: center;
}
div/deep/.btn_head .el-button {
  margin-bottom: 20px;
}
</style>
  